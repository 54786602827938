import React from "react";

import "./style.css";

interface SocialTag {
  href: string;
  icon: string;
  label: string;
}

const SOCIAL_TAGS: SocialTag[] = [
  {
    href: "https://github.com/abellsmythe",
    icon: "fa-github",
    label: "Github",
  },
  {
    href: "https://www.linkedin.com/in/abellsmythe",
    icon: "fa-linkedin",
    label: "LinkedIn",
  },
  {
    href: "https://play.google.com/store/apps/dev?id=5831554056913267594",
    icon: "fa-google-play",
    label: "Google Play",
  },
];

const Social = () => (
  <div className="footer-social-icons">
    {SOCIAL_TAGS.map((element, index) => (
      <a
        key={index}
        href={element.href}
        target="_blank"
        rel="noopener noreferrer"
        title={element.label}
        arial-label={element.label}
      >
        <i className={`fab ${element.icon}`}></i>
      </a>
    ))}
  </div>
);

export default Social;
