import React from "react";

import "./style.css";

export interface MenuElement {
  active: boolean;
  href: string;
  id: string;
  label: string;
  open: boolean;
}

interface PropTypes {
  elements: MenuElement[];
  handleClick: (element: MenuElement) => void;
  opened: boolean;
}

const Menu = ({ elements, handleClick, opened }: PropTypes) => (
  <div className={`menu ${opened ? "menu-pgactive" : ""}`}>
    {elements.length > 0 ? (
      <ul className="menu-list">
        {elements.map((element) => (
          <li key={element.id}>
            <a
              href={element.href}
              className={`link-page ${element.active ? "menu-active" : ""}`}
              onClick={() => {
                handleClick(element);
              }}
            >
              {element.label}
            </a>
          </li>
        ))}
      </ul>
    ) : null}
  </div>
);

export default Menu;
