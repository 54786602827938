import React from "react";

import "./style.css";

interface PropTypes {
  active: boolean;
  children: JSX.Element;
  // @TECHDEBT: do not expect a class name, offer a type instead
  className?: string;
  main?: boolean;
  title?: string;
}

const VCard = ({ active, children, className, main, title }: PropTypes) => (
  <div
    className={`section-vcardbody ${!main ? "section-page" : ""} ${
      className ? className : ""
    } ${active && main ? "section-vcardbody-pgactive" : ""} ${
      active && !main ? "section-page-active" : ""
    }`}
  >
    {title ? <h2 className="section-title">{title}</h2> : null}
    {children}
  </div>
);

export default VCard;
