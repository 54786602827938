import React from "react";

import Menu, { MenuElement } from "../../components/shared/Menu";
import Particles from "../../components/shared/Particles";
import Social from "../../components/shared/Social";
import ProfilePicture from "../../components/ui/ProfilePicture";
import VCard from "../../components/ui/VCard";
import CV from "./components/CV";
import Projects from "./sections/Projects";
import Resume from "./sections/Resume";

import "./style.css";

interface StateTypes {
  elements: MenuElement[];
  opened: boolean;
}

const INITIAL_MENU_STATE: StateTypes = {
  elements: [
    {
      active: true,
      href: "#home",
      id: "home",
      label: "Home",
      open: false,
    },
    {
      active: false,
      href: "#resumen",
      id: "resume",
      label: "Resume",
      open: true,
    },
    {
      active: false,
      href: "#projects",
      id: "projects",
      label: "Projects",
      open: true,
    },
  ],
  opened: false,
};

const Home = () => {
  const [menu, setMenu] = React.useState<StateTypes>(INITIAL_MENU_STATE);

  const handleClickMenu = (currentElement: MenuElement) => {
    const status = !currentElement.active;
    setMenu({
      elements: menu.elements.map((element) => ({
        ...element,
        active:
          element.id === currentElement.id ? status : false,
      })),
      opened: currentElement.open && status,
    });
  };

  return (
    <>
      <Particles />
      <>
        <section className="flex-container">
          <div className="container">
            <Menu
              elements={menu.elements}
              opened={menu.opened}
              handleClick={handleClickMenu}
            />
            <VCard active={menu.opened} main={true} className="section-home">
              <>
                <ProfilePicture />
                <div className="vcard-profile-description">
                  <h1 className="profile-title">
                    Hi! 👋{" "}
                    <p>
                      I'm <span className="color1">Alton Bell Smythe</span>
                    </p>
                  </h1>
                  <h2 className="profile-subtitle">Staff Software Engineer</h2>
                  <hr className="hr1" />
                  <div className="vcard-profile-description-text">
                    <p>
                      Degree in Computer Science and Fullstack Web developer
                      based on Buenos Aires.
                    </p>
                  </div>
                  <div className="vcard-profile-description-feature">
                    <div className="vcard-profile-description-ft-item">
                      <p>
                        email:{" "}
                        <a
                          href="mailto:abellsmythe@gmail.com"
                          className="link-page"
                        >
                          abellsmythe@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="vcard-footer">
                  <Social />
                </div>
              </>
            </VCard>
            <VCard
              active={menu.elements[1].active}
              title={menu.elements[1].label}
            >
              <Resume />
            </VCard>
            <VCard
              active={menu.elements[2].active}
              title={menu.elements[2].label}
            >
              <Projects />
            </VCard>
          </div>
        </section>
        <CV link="alton_bell_smythe_cv_resume.pdf" />
      </>
    </>
  );
};

export default Home;
