import React from "react";

import "./style.css";

const Projects = () => (
  <div className="section-apps">
    <div className="apps-list">
      <div className="app-item">
        <a
          href="https://play.google.com/store/apps/details?id=com.abellsmythe.snake"
          className="app-thumbnail nivobox"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundImage: "url('img/apps/snake/splash.png')",
          }}
        >
          <div className="app-info-wrapper">
            <div className="app-info">
              <h2 className="app-title">Snake Retro</h2>
              <p className="app-description">
                Android old classic snake game that everyone remembers with an
                awesome retro-style!
              </p>
            </div>
          </div>
        </a>
      </div>
      <div className="app-item">
        <a
          href="https://play.google.com/store/apps/details?id=com.abellsmythe.tapthenumber"
          className="app-thumbnail nivobox"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundImage: "url('img/apps/tap-the-number/splash.png')",
          }}
        >
          <div className="app-info-wrapper">
            <div className="app-info">
              <h2 className="app-title">Tap The Number</h2>
              <p className="app-description">
                Android game tap the number as fast as you can
              </p>
            </div>
          </div>
        </a>
      </div>
      <div className="app-item">
        <a
          href="https://async-team.com"
          className="app-thumbnail nivobox"
          target="_blank"
          rel="noopener noreferrer"
          arial-label="Async collaboration for remote Teams"
          title="Async collaboration for remote Teams"
          style={{
            backgroundImage: "url('img/apps/async-team/splash.png')",
          }}
        >
          <div className="app-info-wrapper">
            <div className="app-info">
              <h2 className="app-title">Async Team</h2>
              <p className="app-description">
                Async collaboration for remote Teams
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
);

export default Projects;
