import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import SnakeRetro from "./pages/PrivacyPolicy/SnakeRetro";
import TapTheNumber from "./pages/PrivacyPolicy/TapTheNumber";
// import registerServiceWorker from "./registerServiceWorker";

import "./index.css";

const Root = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        exact
        path="/privacy-policy/snake-retro/"
        component={SnakeRetro} />
      <Route
        exact
        path="/privacy-policy/tap-the-number/"
        component={TapTheNumber} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

ReactDOM.render(<Root />, document.getElementById("root"));
// registerServiceWorker();
