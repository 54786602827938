import React from "react";

import Certificate from "./components/Certificate";

const Resume = () => (
  <>
    <div className="section-education">
      <h2 className="section-subtitle">
        <i className="fa fa-user"></i>&nbsp; About me
      </h2>
      <div className="resume-item">
        <div>
          <p>
            I'm a Full Stack Web Developer. I have experience working in teams
            and in multidisciplinary projects with a strong leadership and
            planning skills.
          </p>
          <p>
            I'm a lover of new technologies and I consider myself self-taught.
            Established reputation as a problem-solver, team leader, software
            architect, and agile practitioner. Generally, my decisions are data
            driven, I always try to think strategically to provide long-term
            vision and translating business problems into effective technical
            solutions.{" "}
          </p>
          <p>
            I'm really lucky to dedicate myself to do what makes me happy,
            programming is both my job and a hobby. I'm a strong believer that
            trust, values, teamwork and a great communication are the keys to
            achieve great results.{" "}
          </p>
          <p>
            I'm Always looking for the next challenge, learning, trying new
            things and looking to improve my skills and maturing further my
            proficiency.
          </p>
        </div>
      </div>
    </div>
    <div className="section-education">
      <h2 className="section-subtitle">
        <i className="fa fa-briefcase"></i>&nbsp; Work Experience
      </h2>
      <div className="resume-item">
        <h3 className="section-item-title-1">
          <a href="https://mural.co/" target="_blank" rel="noopener noreferrer">
            Mural
          </a>
        </h3>
        <h4 className="job">
          Staff Software Engineer and Team Architect{" "}
          <span className="job-date">April 2021 - Present</span>
        </h4>
        <div className="job-description">
          <p>
            My main task is to unblock anybody on my team. Always trying to
            share knowledge and help them to improve their skills. I enjoy
            looking for performance spots, database/query issues, and love
            learning! Always trying to spread the knowledge, fomenting good
            practices at the team especially in code reviews and pair
            programming. Also, working closely with Product, DevOps, Security,
            and other teams, to develop together effective technical solutions.
          </p>
        </div>
        <h4 className="job">
          Senior Full Stack Engineer{" "}
          <span className="job-date">April 2019 - April 2021</span>
        </h4>
        <div className="job-description">
          <p>
            I work as a Full Stack Engineer mainly focus on the development of
            features to provide management capabilities. We use{" "}
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ReactJS
            </a>{" "}
            in the front-end and{" "}
            <a
              href="https://expressjs.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Express
            </a>{" "}
            with{" "}
            <a
              href="https://nodejs.org/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NodeJS
            </a>
            ,{" "}
            <a
              href="https://www.mongodb.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              MongoDB
            </a>{" "}
            and{" "}
            <a
              href="https://redis.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redis
            </a>{" "}
            at the back-end, both using{" "}
            <a
              href="https://www.typescriptlang.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Typescript
            </a>
            , testing with{" "}
            <a
              href="https://cucumber.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cucumber
            </a>{" "}
            and everything working together with{" "}
            <a
              href="https://www.docker.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Docker
            </a>
          </p>
        </div>
      </div>
      <div className="resume-item">
        <h3 className="section-item-title-1">
          <a
            href="https://www.logoscorp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Globant
          </a>
        </h3>
        <h4 className="job">
          Web UI Developer{" "}
          <span className="job-date">August 2018 - April 2019</span>
        </h4>
        <div className="job-description">
          <p>
            I worked with{" "}
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ReactJS
            </a>{" "}
            and{" "}
            <a
              href="https://redux.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redux
            </a>{" "}
            in the development of complex tools using SCRUM. Testing with{" "}
            <a
              href="https://jestjs.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jest
            </a>{" "}
            and continuous deployment/integration tools like{" "}
            <a
              href="https://jenkins.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jenkins
            </a>
            .
          </p>
        </div>
      </div>
      <div className="resume-item">
        <h3 className="section-item-title-1">
          <a
            href="https://www.logoscorp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LogosCorp
          </a>
        </h3>
        <h4 className="job">
          Web Developer{" "}
          <span className="job-date">November 2017 - August 2018</span>
        </h4>
        <div className="job-description">
          <p>
            I worked with{" "}
            <a
              href="https://laravel.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Laravel
            </a>{" "}
            in the development of custom websites and APIs, I also developep
            with{" "}
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ReactJS
            </a>{" "}
            for web and{" "}
            <a
              href="http://www.reactnative.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React Native
            </a>{" "}
            for mobile, in both cases using SCRUM with{" "}
            <a
              href="https://atlassian.com/software/jira"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jira
            </a>{" "}
            software.
          </p>
        </div>
      </div>
      <div className="resume-item">
        <h3 className="section-item-title-1">
          <a
            href="http://www.une.edu.ve/une/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nueva Esparta University
          </a>
        </h3>
        <h4 className="job">
          Head Developer{" "}
          <span className="job-date">September 2017 - November 2017</span>
        </h4>
        <div className="job-description">
          <p>
            I led the systems area of ​​the Nueva Esparta University constituted
            by 6 developers assigning and planning the corresponding tasks to
            carry out the requirements of the users in a successful way and
            implement the GitFlow workflow
          </p>
        </div>
      </div>
      <div className="resume-item">
        <h3 className="section-item-title-1">
          <a
            href="http://www.une.edu.ve/une/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nueva Esparta University
          </a>
        </h3>
        <h4 className="job">
          Junior Developer{" "}
          <span className="job-date">May 2015 - September 2017</span>
        </h4>
        <div className="job-description">
          <p>
            I worked in the web system for students and staff of the Nueva
            Esparta University developing in PHP with{" "}
            <a
              href="https://framework.zend.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ZendFramework
            </a>{" "}
            using{" "}
            <a
              href="https://getbootstrap.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bootstrap
            </a>{" "}
            and{" "}
            <a
              href="https://jquery.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              jQuery
            </a>
            , additionally my degree work was the realization of a mobile app in
            Android for the Nueva Esparta University, it was developed in{" "}
            <a
              href="https://angularjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AngularJS
            </a>{" "}
            with{" "}
            <a
              href="https://ionicframework.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ionic
            </a>{" "}
            using material design and the API was made with{" "}
            <a
              href="https://lumen.laravel.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lumen
            </a>{" "}
            (
            <a
              href="https://laravel.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Laravel
            </a>
            ).
          </p>
        </div>
      </div>
    </div>
    <div className="section-education">
      <h2 className="section-subtitle">
        <i className="fa fa-university"></i>&nbsp; Education
      </h2>
      <div className="resume-item">
        <h3 className="section-item-title-1">Computer Science</h3>
        <h4 className="graduation-time">
          <a
            href="http://une.edu.ve/une/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nueva Esparta Univeristy
          </a>{" "}
          - <span className="graduation-date">Graduation May 2018</span>
        </h4>
        <div className="graduation-description">
          <p>Computer degree, taken in my native country Venezuela</p>
        </div>
      </div>
    </div>
    <div className="section-education">
      <h2 className="section-subtitle">
        <i className="fa fa-address-card"></i>&nbsp; Certifications
      </h2>
      <Certificate
        credentialID="1fe3ec82260d3f7ffa5ad23a91a4cb26e687baa1"
        date="January 2019 - Present"
        link="https://globant.acamica.com/cert/1fe3ec82260d3f7ffa5ad23a91a4cb26e687baa1"
        name="Asincronismo en Javascript"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="5bc1008c93bbc3208321dbbe"
        date="October 2018 - Present"
        link="https://courses.wesbos.com/account/certificate/5bc1008c93bbc3208321dbbe"
        name="Advanced React and GraphQL"
        organization="Wes Bos"
        organizationImg="img/wes-bos.png"
        organizationImgAlt="Wes Bos"
        organizationLink="https://wesbos.com/"
      />
      <Certificate
        credentialID="02cf15c0d57326ef8eb8fef7cd8fca06d896f425"
        date="October 2018 - Present"
        link="https://globant.acamica.com/cert/02cf15c0d57326ef8eb8fef7cd8fca06d896f425"
        name="Express"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="268e73101e2d233c2f51c79e37b204bb02c5e832"
        date="October 2018 - Present"
        link="https://globant.acamica.com/cert/268e73101e2d233c2f51c79e37b204bb02c5e832"
        name="Fundamentos de ECMAScript 6"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="df59aba41078f19b191eedf0990fccae4aadfb38"
        date="October 2018 - Present"
        link="https://globant.acamica.com/cert/df59aba41078f19b191eedf0990fccae4aadfb38"
        name="Introducción a React"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="38112d4d817a649c08e18f070cb35a69280a6ac5"
        date="October 2018 - Present"
        link="https://globant.acamica.com/cert/38112d4d817a649c08e18f070cb35a69280a6ac5"
        name="Node.js, el comienzo"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="c7edef5943127f5ae0f197e74c23e7884ad2fa59"
        date="October 2018 - Present"
        link="https://globant.acamica.com/cert/c7edef5943127f5ae0f197e74c23e7884ad2fa59"
        name="Programación Funcional"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="7203e50ca81691efb34da03ccd091c676a28c1d0"
        date="October 2018 - Present"
        link="https://globant.acamica.com/cert/7203e50ca81691efb34da03ccd091c676a28c1d0"
        name="Prototipos y clases en JavaScript"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="df3340fcfac5ec6bcd935d6b67302d4158e39394"
        date="October 2018 - Present"
        link="https://globant.acamica.com/cert/df3340fcfac5ec6bcd935d6b67302d4158e39394"
        name="React/Redux"
        organization="Acámica"
        organizationImg="img/acamica.png"
        organizationImgAlt="Acámica"
        organizationLink="https://www.acamica.com/"
      />
      <Certificate
        credentialID="UC-ROU4H59G"
        date="May 2018 - Present"
        link="https://www.udemy.com/certificate/UC-ROU4H59G/"
        name="Intro to Git"
        organization="Udemy"
        organizationImg="img/udemy.png"
        organizationImgAlt="Udemy"
        organizationLink="https://www.udemy.com"
      />
      <Certificate
        credentialID="UC-TW0QNNJ0"
        date="May 2018 - Present"
        link="https://www.udemy.com/certificate/UC-TW0QNNJ0/"
        name="Start Kali Linux, Ethical Hacking and Penetration Testing"
        organization="Udemy"
        organizationImg="img/udemy.png"
        organizationImgAlt="Udemy"
        organizationLink="https://www.udemy.com"
      />
    </div>
    <div className="section-education">
      <h2 className="section-subtitle">
        <i className="fa fa-book"></i>&nbsp; Languages
      </h2>
      <div className="resume-item">
        <h3 className="section-item-title-1">Spanish</h3>
        <div className="language-description">
          <p>Native or bilingual proficiency</p>
        </div>
      </div>
      <div className="resume-item">
        <h3 className="section-item-title-1">English</h3>
        <div className="language-description">
          <p>Professional working proficiency</p>
        </div>
      </div>
      <div className="resume-item">
        <h3 className="section-item-title-1">French</h3>
        <div className="language-description">
          <p>Limited working proficiency</p>
          <ul>
            <li>
              <p>DELF A1</p>
            </li>
            <li>
              <p>DELF A2</p>
            </li>
            <li>
              <p>DELF B1</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>
);

export default Resume;
