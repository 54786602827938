import React from "react";

interface CertificatePropTypes {
  credentialID: string;
  date: string;
  link: string;
  name: string;
  organization: string;
  organizationImg: string;
  organizationImgAlt: string;
  organizationLink: string;
}

const Certificate = ({
  credentialID,
  date,
  link,
  name,
  organization,
  organizationImg,
  organizationImgAlt,
  organizationLink,
}: CertificatePropTypes) => (
  <div className="resume-item">
    <h3 className="section-item-title-1">{name}</h3>
    <h4 className="graduation-time">
      <a href={organizationLink} target="_blank" rel="noopener noreferrer">
        {organization}
      </a>{" "}
      - <span className="graduation-date">{date}</span>
    </h4>
    <div className="graduation-description">
      <img
        alt={organizationImgAlt}
        height={60}
        loading="lazy"
        src={organizationImg}
        width={60}
      />
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="certification"
      >
        Credential ID {credentialID}
      </a>
    </div>
  </div>
);

export default Certificate;
