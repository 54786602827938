import React from "react";

import "./style.css";

const ProfilePicture = () => (
  <div className="vcard-profile-pic">
    <img
      alt="Alton Bell Smythe"
      height={204}
      src="/img/profile.jpg"
      width={200}
    />
  </div>
);

export default ProfilePicture;
