import React, { Component } from "react";
import { Helmet } from "react-helmet";

import "../style.css";

const TapTheNumberPrivacyPolicy = () => (
  <main>
    <Helmet>
      <title>Privacy Policy - Tap The Number App</title>
    </Helmet>
    <h1>
      <strong>PRIVACY POLICY - TAP THE NUMBER APP</strong>
    </h1>
    <p>
      Your privacy is important to us, and we take our responsibility of caring
      for it seriously. We respect your privacy rights and are committed to
      protecting the personal information that is collected about you. This
      Privacy Policy describes what information Alton Bell Smythe collects and
      how we use, disclose, transfer, and store it. If you do not want Alton
      Bell Smythe to collect, store, use or share your information in the ways
      described in this Privacy Policy, you may not be able to play our games or
      use our services.
    </p>
    <p>By using the Services, you hereby consent to this Privacy Policy.</p>
    <p>
      This Privacy Policy is effective as of May 28, 2020 and was last modified
      on May 28, 2020.
    </p>
    <p>
      If you have any questions, comments or concerns regarding our Privacy
      Policy and/or practices, please see the&nbsp;“<u>How to contact us</u>
      ”&nbsp;section below.
    </p>
    <p>&nbsp;</p>
    <h2>1 – Information we collect</h2>
    <p>
      Alton Bell Smythe does not directly collect any information about its
      usage but some informations can be shared with the following third
      parties:
    </p>
    <ul>
      <li>
        <a
          href="https://play.google.com/apps/publish/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Play Console
        </a>
        to handle the app distribution and the beta testing. No personal
        information is ever sent to third parties, only an anonymous id.
        Services may collect the user's IP. Some device information may be
        included for debugging purposes.
      </li>
    </ul>
    <p>&nbsp;</p>
    <h2>2 – Third-party services</h2>
    <p>
      The Services may link to external sites that are beyond our control. This
      Privacy Policy only covers the way we treat personal information and
      non-personal information we collect on the Services. By accessing any
      external sites, you agree to the privacy policies of those sites. Please
      understand that external sites may have different policies with respect to
      the collection, use and disclosure of your personal information. We have
      no control over, and are not responsible for, the privacy practices of
      such third parties.
    </p>
    <p>
      Therefore, you are encouraged to review the privacy policies of all third
      party services. Without limiting anything in the following section, we are
      not liable in any way for the actions, inactions or policies of any
      external services.
    </p>
    <p>&nbsp;</p>
    <h2>3 – Changes to our Privacy Policy</h2>
    <p>
      We reserve the right to change this Privacy Policy at any time in order to
      stay compliant with relevant laws. In the event that we make material
      changes to this Privacy Policy, they will be re-posted in the "Privacy"
      section of our websites and apps with the date the modifications were made
      indicated at the top of the page. Therefore, please review this Privacy
      Policy from time to time so that you are aware when any changes are made
      to it. Your continued use of the Services after such change(s) constitutes
      your acceptance of any such change(s), and if you do not accept any
      changes, you may choose not to use the Services or opt out by sending us
      appropriate notice. If you would like further information about any recent
      changes made, please send your request to&nbsp;
      <a href="mailto:abellsmythe@gmail.com">abellsmythe@gmail.com</a>
      &nbsp;and include “Information about Changes” in the subject line.
    </p>
    <p>&nbsp;</p>
    <h2>4 – Legal disclaimer</h2>
    <p>
      Our Services operate AS IS, and we do not represent or warrant that our
      Services will be available at all times, or that your use of the Services
      will be uninterrupted or error-free. We are not responsible for your
      ability to access the Services or matters beyond our control. This Privacy
      Policy is governed by the laws of your country of residence. Any dispute
      arising from the use of the Services shall be submitted to the competent
      courts of your country of residence.
    </p>
    <p>&nbsp;</p>
    <h2>5 – How to contact us</h2>
    <p>
      If you have any questions, comments or concerns regarding our Privacy
      Policy and/or practices, please contact us:
    </p>
    <ol>
      <li>
        1. by sending an email to&nbsp;
        <a href="mailto:abellsmythe@gmail.com">abellsmythe@gmail.com</a>
      </li>
      <li>
        2. by writing to the Alton Bell Smythe, Av. Las Heras 3030, 1425 Buenos
        Aires, Argentina
      </li>
    </ol>
  </main>
);

export default TapTheNumberPrivacyPolicy;
