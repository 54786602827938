import React from "react";

import "./style.css";

interface PropTypes {
  link: string;
}

const CV = ({ link }: PropTypes) => (
  <a className="btn-cv" href={link} rel="noopener noreferrer" target="_blank">
    CV
  </a>
);

export default CV;
