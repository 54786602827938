import React from "react";

import "./style.css";

const NotFound = () => (
  <div className="notfound__container">
    <div className="boo-wrapper">
      <div className="boo">
        <div className="face"></div>
      </div>
      <div className="shadow"></div>
      <div className="text">
        <h1>Whoops!</h1>
        <p>
          <h2>We couldn't find the page you</h2>
          <h2>were looking for.</h2>
        </p>
      </div>
    </div>
  </div>
);

export default NotFound;
